import React, { useMemo } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { GlobalValuesProvider } from "../globalValues"
import SEO from "../components/seo"
import styled from "styled-components"
import { ThemeProvider } from "../styles"
import { Slices } from "../Slices"

export const pressReleaseFragment = graphql`
  fragment ContentfulPostContent on ContentfulPost {
    __typename
    browserPageTitle
    title
    metaDescription
    publicationDate
    slug
    teaserText {
      teaserText
    }
    slices {
      __typename
      ... on ContentfulAccordion {
        ...ContentfulAccordionSliceFragment
      }
      ... on ContentfulHubspotFormSlice {
        ...ContentfulHubspotFormSliceFragment
      }
      ... on ContentfulQuoteSlice {
        ...ContentfulQuoteSliceFragment
      }
      ... on ContentfulRichtextSlice {
        ...ContentfulRichtextSliceFragment
      }
      ... on ContentfulDividerSlice {
        ...ContentfulDividerSliceFragment
      }
      ... on ContentfulHeroMediaSlice {
        ...ContentfulHeroMediaSliceFragment
      }
      ... on ContentfulIntroSlice {
        ...ContentfulIntroSliceFragment
      }
      ... on ContentfulBannerSlice {
        ...ContentfulBannerSliceFragment
      }
      ... on ContentfulColumnTeaserSlice {
        ...ContentfulColumnTeaserSliceFragment
      }
      ... on ContentfulImageCarrouselSlice {
        ...ContentfulImageCarrouselSliceFragment
      }
      ... on ContentfulEditorialSlice {
        ...ContentfulEditorialSliceFragment
      }
      ... on ContentfulImageAndTextSlice {
        ...ContentfulImageAndTextSliceFragment
      }
      ... on ContentfulBigTeaserSlice {
        ...ContentfulBigTeaserSliceFragment
      }
      ... on ContentfulSharingSlice {
        ...ContentfulSharingSliceFragment
      }
      ... on ContentfulBigDisruptorSlice {
        ...ContentfulBigDisruptorSliceFragment
      }
      ... on ContentfulBigEditorialSlice {
        ...ContentfulBigEditorialSliceFragment
      }
      ... on ContentfulImageColumnsSlice {
        ...ContentfulImageColumnsSliceFragment
      }
      ... on ContentfulDualImageCarrouselSlice {
        ...ContentfulDualImageCarrouselSliceFragment
      }
      ... on ContentfulImageBackgroundCopySlice {
        ...ContentfulImageBackgroundCopySliceFragment
      }
      ... on ContentfulModelComparisonSlice {
        ...ContentfulModelComparisonSliceFragment
      }
      ... on ContentfulTechnicalSpecsSlice {
        ...ContentfulTechnicalSpecsSliceFragment
      }
      ... on ContentfulNewsletterSignUpSlice {
        ...ContentfulNewsletterSignUpSliceFragment
      }
      ... on ContentfulBackgroundImageParallaxSlice {
        ...ContentfulBackgroundImageParallaxSliceFragment
      }
      ... on ContentfulHeroSplashVideoSlice {
        ...ContentfulHeroSplashVideoSliceFragment
      }
      ... on ContentfulGarageFinderSlice {
        ...ContentfulGarageFinderSliceFragment
      }
      ... on ContentfulParallaxMediaSlice {
        ...ContentfulParallaxMediaSliceFragment
      }
      ... on ContentfulScooterModelSlice {
        ...ContentfulScooterModelSliceFragment
      }
      ... on ContentfulAnimatedMapSlice {
        ...ContentfulAnimatedMapSliceFragment
      }
      ... on ContentfulPostsSlice {
        ...ContentfulPostsSliceFragment
      }
    }
  }
`

export const query = graphql`
  query PressReleaseQuery(
    $mainContentfulId: String!
    $contentful_id: String!
    $slug: String
    $node_locale: String
    $allAdditionalEntryIdsGlob: String!
  ) {
    allContentfulPost(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        __typename
        node_locale
        slug
      }
    }

    contentfulPost(slug: { eq: $slug }, node_locale: { eq: $node_locale }) {
      ...ContentfulPostContent
    }

    allAdditionalEntries: allContentfulEntry(
      filter: {
        contentful_id: { glob: $allAdditionalEntryIdsGlob }
        node_locale: { eq: $node_locale }
      }
    ) {
      nodes {
        ... on ContentfulPost {
          __typename
          browserPageTitle
          contentful_id
          slug
          title
          teaserText {
            teaserText
          }
          teaserImage {
            gatsbyImageData
          }
        }
        ... on ContentfulCmsPage {
          __typename
          browserPageTitle
          contentful_id
          slug
          title
        }
      }
    }

    ...GlobalValues
    ...DefaultMainValues
  }
`

const PageSlicesContainer = styled.main``

const PressReleasePage = ({ data, pageContext }) => {
  const page = data.contentfulPost
  const {
    language,
    country,
    footerData,
    pageMeta,
    additionalEntryReferencesBySlice,
  } = pageContext

  const localizedNodes = data.allContentfulPost.nodes

  const allAdditionalEntries = data.allAdditionalEntries.nodes
  const additionalEntriesBySlice = useMemo(() => {
    const result = {}

    for (const sliceId of Object.keys(additionalEntryReferencesBySlice)) {
      result[sliceId] = allAdditionalEntries.filter(({ id }) =>
        additionalEntryReferencesBySlice[sliceId].some(
          sliceEntryReference => sliceEntryReference.id === id
        )
      )
    }

    return result
  }, [additionalEntryReferencesBySlice, allAdditionalEntries])

  return (
    <GlobalValuesProvider value={data.contentfulGlobal}>
      <Layout
        language={language}
        country={country}
        mainData={data.contentfulMain}
        footerData={footerData}
        localizedNodes={localizedNodes}
      >
        <ThemeProvider themeName="stone">
          <SEO
            title={page.browserPageTitle ?? page.title}
            description={page.metaDescription}
            localizedNodes={localizedNodes}
            pageMeta={pageMeta}
          />

          <PageSlicesContainer>
            <Slices
              page={page}
              slices={page.slices}
              additionalEntriesBySlice={additionalEntriesBySlice}
            />
          </PageSlicesContainer>
        </ThemeProvider>
      </Layout>
    </GlobalValuesProvider>
  )
}

export default PressReleasePage
